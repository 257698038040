function ContribIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 24 24"
            {...props}>
            <path d="M22 18.055v2.458C22 22.438 17.345 24 12 24c-5.344 0-10-1.562-10-3.487v-2.458c2.418 1.738 7.005 2.256 10 2.256 3.006 0 7.588-.523 10-2.256zm-10-3.409c-3.006 0-7.588-.523-10-2.256v2.434c0 1.926 4.656 3.487 10 3.487 5.345 0 10-1.562 10-3.487V12.39c-2.418 1.738-7.005 2.256-10 2.256zM12 0C6.656 0 2 1.562 2 3.488s4.656 3.487 10 3.487c5.345 0 10-1.562 10-3.487C22 1.562 17.345 0 12 0zm0 8.975c-3.006 0-7.588-.523-10-2.256v2.44c0 1.926 4.656 3.487 10 3.487 5.345 0 10-1.562 10-3.487v-2.44c-2.418 1.738-7.005 2.256-10 2.256z" />
        </svg>
    );
}

export default ContribIcon;
