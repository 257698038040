function PartnerIcon(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={18}
            height={18}
            viewBox="0 0 24 24"
            {...props}>
            <path d="M11.329 19.6a.939.939 0 01-1.317.202.941.941 0 01-.202-1.315l1.077-1.456a.94.94 0 011.315-.199.934.934 0 01.201 1.313L11.329 19.6zm-.825-2.839a.942.942 0 10-1.518-1.113l-1.073 1.449a.942.942 0 001.517 1.115l1.074-1.451zm-1.9-1.388a.94.94 0 00-1.517-1.113l-1.083 1.461a.93.93 0 00.209 1.304.93.93 0 001.308-.19l1.083-1.462zm-1.898-1.386a.938.938 0 00-.203-1.315.94.94 0 00-1.315.201l-1.075 1.451a.94.94 0 101.517 1.113l1.076-1.45zM24 5.549s-1.555.301-2.667.479c-2.146.344-4.144-.416-6.361-1.562-.445-.229-.957-.466-1.458-.466-.461 0-.913.209-1.292.639-1.366 1.547-2.16 2.915-3.785 3.864-.801.468.14 1.934 1.86 1.331.878-.308 1.736-.895 2.706-1.677.762-.615 1.22-.524 1.879.135 1.238 1.238 5.404 5.351 5.404 5.351 1.317-.812 2.422-1.312 3.713-1.792V5.549zM13.476 18.211c-.158.459-.618 1.001-.953 1.455.297.235.608.334.882.334.717 0 1.188-.671.542-1.318l-.471-.471zm6.506-3.463c-1.07-1.055-4.732-4.667-5.803-5.713a.472.472 0 00-.608-.044c-.639.464-2.082 1.485-2.944 1.788-1.685.59-3.115-.222-3.422-1.359a1.547 1.547 0 01.727-1.781c1.008-.589 1.657-1.375 2.456-2.363-.695-.539-1.35-.732-1.991-.732-1.706 0-3.317 1.366-5.336 1.231C1.688 5.685 0 5.372 0 5.372v6.333c1.476.321 2.455.464 3.92 1.199l.462-.624a1.943 1.943 0 013.425.596c.951 0 1.667.602 1.898 1.387a1.932 1.932 0 011.897 1.385c1.171 0 2.017.92 1.981 2.007l1.168 1.168a.941.941 0 101.331-1.332l-1.686-1.687c-.22-.22.113-.553.333-.333l2.032 2.033a.94.94 0 101.331-1.331l-2.501-2.502c-.221-.218.113-.553.333-.333l2.7 2.701a.94.94 0 001.331 0 .933.933 0 00.027-1.291z" />
        </svg>
    );
}

export default PartnerIcon;
